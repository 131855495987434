import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import {
  Container,
  CardContent,
  Typography,
  Card,
  Button,
  CardActions,
  Grid,
} from "@mui/material"

const BlogPage = ({ data }) => {
  return (
    <Layout pageTitle="NeoXR Blog Posts">
      <Container sx={{margin:"120px auto", }}>
      <Typography
          variant="h2"
          component="h2"
          sx={{
            fontFamily: "Archivo Black, sans-serif",
            fontSize: { xs: "1.5rem", md: "2rem" },
            color: "black",
            textAlign: "center",
            lineHeight: "100%",
            width: "100%",
          }}
        >
         Our Blogs
        </Typography>
        <Typography
          variant="h2"
          component="h2"
          sx={{
            fontFamily: "Inter, sans-serif",
            fontSize: { xs: "0.6rem", md: "1rem" },
            color: "black",
            textAlign: "center",
            margin:"30px 0",
            lineHeight: "150%",
            width: "100%",
          }}
        >
          Check out our latest news and tutorials
        </Typography>

        <Grid
          container
          spacing={0}
          sx={{ position: "relative", justifyContent:{xs: "center",md:"flex-start"}, }}
        >
          {data.allMdx.nodes.map(node => (
            <Grid item sx={{ maxWidth:{xs: "500px",md:"320px"}, padding: "20px 20px" }}>
              <Card
                variant="outlined"
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {node.frontmatter.description}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {node.frontmatter.title}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {node.frontmatter.date}
                  </Typography>
                  <Typography variant="body2">
                    {node.frontmatter.excerpt}
                    <br />
                    {'"a benevolent smile"'}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    aria-label="share"
                    sx={{
                      borderRadius: 8,
                      margin: "0 10px",
                      marginLeft: "auto",
                    }}
                  >
                    <Link to={`/blog/${node.slug}`}>learn More</Link>
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { path: { eq: "/blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          author
          excerpt
          title
          description
        }
        id
        slug
      }
    }
  }
`

export default BlogPage
